(function () {
    'use strict';
    /*
    domain
    instance: pl | ro - rumunian | int - international
    pixel - fb-pixel enabled
    ua - google analytics active/not active
    conversion - adwords conversion
    */
    angular
    .module('plagiat2017App')
    .constant('DOMAINS', [
        { domain: 'localhost',                instance: 'pl', lang : 'en', pixel: false, ua: true, conversion: false, fb: true, disableRegister:false, redirect: false, landingLang: 'en', oauth: ['sdu'], saml: ['other','saml3', 'valencia']  },
        { domain: '127.0.0.1',                instance: 'pl', lang : 'en', pixel: false, ua: true, conversion: false, fb: true, disableRegister:false, redirect: false, landingLang: 'en', oauth: ['sdu'], saml: ['other','saml3', 'valencia']  },
        { domain: 'st.plagiat.pl',            instance: 'pl', lang : 'pl', pixel: true,  ua: false, conversion: false, fb: true, disableRegister:false, redirect: true, landingLang: 'pl', oauth: ['sdu'], saml: ['other', 'valencia'] },
        { domain: 'preprod.plagiat.pl',       instance: 'pl', lang : 'pl', pixel: true,  ua: true, conversion: true, fb: true, disableRegister:false, redirect: true, landingLang: 'pl' },
        { domain: 'test.plagiat.pl',          instance: 'pl', lang : 'pl', pixel: true,  ua: true, conversion: true, fb: true, disableRegister:false, redirect: true },
        { domain: 'panel.plagiat.pl',         instance: 'pl', lang : 'pl', pixel: true,  ua: true, conversion: true, fb: true, disableRegister:false, redirect: true, landingLang: 'pl' },
        { domain: 'sprawdz-prace.plagiat.pl', instance: 'pl', lang : 'pl', pixel: true,  ua: true, conversion: true, fb: false, disableRegister:true, redirect: true, landingLang: 'pl'},
        
        { domain: 'st.akambiko.pl',      instance: 'aka', lang : 'pl', pixel: false, ua: false, conversion: false, fb: false, disableRegister:true, redirect: true },
        { domain: 'preprod.akambiko.pl', instance: 'aka', lang : 'pl', pixel: false, ua: false, conversion: false, fb: false, disableRegister:true, redirect: true },
        { domain: 'panel.akambiko.pl',   instance: 'aka', lang : 'pl', pixel: false, ua: false, conversion: false, fb: false, disableRegister:true, redirect: true },
        
        { domain: 'st.sistemantiplagiat.ro',              instance: 'ro', lang : 'ro', pixel: false, ua: false, conversion: false, fb: true, disableRegister:false, redirect: false, landingLang: 'ro' },
        { domain: 'test.sistemantiplagiat.ro',            instance: 'ro', lang : 'ro', pixel: false, ua: false, conversion: false, fb: true, disableRegister:false, redirect: false },
        { domain: 'preprod.sistemantiplagiat.ro',         instance: 'ro', lang : 'ro', pixel: false, ua: true, conversion: true, fb: true, disableRegister:false, redirect: true, landingLang: 'ro' },
        { domain: 'panel.sistemantiplagiat.ro',           instance: 'ro', lang : 'ro', pixel: false, ua: true, conversion: true, fb: true, disableRegister:false, redirect: true, landingLang: 'ro' },
        { domain: 'verifica-textul.sistemantiplagiat.ro', instance: 'ro', lang : 'ro', pixel: false, ua: true, conversion: true, fb: false, disableRegister:true, redirect: true, landingLang: 'ro' },
        
        { domain: 'st.strikeplagiarism.com',          instance: 'int', lang : 'en', pixel: false, ua: true, conversion: true, fb: true, disableRegister:false, redirect: true, landingLang: 'en', oauth: ['satbayev'] },
        { domain: 'test.strikeplagiarism.com',        instance: 'int', lang : 'en', pixel: false, ua: false, conversion: false, fb: true, disableRegister:false, redirect: true, landingLang: 'en' },
        { domain:                      'check-paper.strikeplagiarism.com', instance: 'int', lang : 'en', pixel: false, ua: true, conversion: true, fb: true, disableRegister:false, redirect: true, landingLang: 'en' },
        { domain:       'check-paper-for-plagiarism.strikeplagiarism.com', instance: 'int', lang : 'en', pixel: false, ua: true, conversion: true, fb: true, disableRegister:false, redirect: true, landingLang: 'en' },
        { domain:      'plagiat-tekseru-antiplagiat.strikeplagiarism.com', instance: 'int', lang : 'kk', pixel: false, ua: true, conversion: true, fb: true, disableRegister:false, redirect: true, landingLang: 'kk' },
        { domain:        'prover-rabotu-antiplagiat.strikeplagiarism.com', instance: 'int', lang : 'ru', pixel: false, ua: true, conversion: true, fb: true, disableRegister:false, redirect: true, landingLang: 'ru' },
        { domain:                      'antiplagiat.strikeplagiarism.com', instance: 'int', lang : 'ru', pixel: false, ua: true, conversion: true, fb: true, disableRegister:false, redirect: true, landingLang: 'ru' },
        { domain: 'perevirka-na-plagiat-antiplagiat.strikeplagiarism.com', instance: 'int', lang : 'uk', pixel: false, ua: true, conversion: true, fb: true, disableRegister:false, redirect: true, landingLang: 'uk' },
        { domain:    'plagiata-yoxlamaq-antiplagiat.strikeplagiarism.com', instance: 'int', lang : 'az', pixel: false, ua: true, conversion: true, fb: true, disableRegister:false, redirect: true, landingLang: 'az' },
        
        { domain:          'plagiatni-tekshiring-antiplagiat.strikeplagiarism.com', instance: 'int', lang : 'uz', pixel: false, ua: true, conversion: true, fb: true, disableRegister:false, redirect: true, landingLang: 'uz' },
        { domain:               'dokument-auf-plagiat-prufen.strikeplagiarism.com', instance: 'int', lang : 'de', pixel: false, ua: true, conversion: true, fb: true, disableRegister:false, redirect: true, landingLang: 'de' },
        { domain: 'sheamotsmet-dokumenti-plagiatis-shesakheb.strikeplagiarism.com', instance: 'int', lang : 'ka', pixel: false, ua: true, conversion: true, fb: true, disableRegister:false, redirect: true, landingLang: 'ka' },
        { domain:       'proverete-dokumenta-za-plagiat-stvo.strikeplagiarism.com', instance: 'int', lang : 'bg', pixel: false, ua: true, conversion: true, fb: true, disableRegister:false, redirect: true, landingLang: 'bg' },
        { domain:     'intihal-ile-ilgili-belgeyi-kontrol-et.strikeplagiarism.com', instance: 'int', lang : 'tr', pixel: false, ua: true, conversion: true, fb: true, disableRegister:false, redirect: true, landingLang: 'tr' },
        { domain:          'comprobar-documento-sobre-plagio.strikeplagiarism.com', instance: 'int', lang : 'es', pixel: false, ua: true, conversion: true, fb: true, disableRegister:false, redirect: true, landingLang: 'es' },
        
        { domain: 'preprod.strikeplagiarism.com',     instance: 'int', lang : 'en', pixel: false, ua: false, conversion: true, fb: true, disableRegister:false, redirect: true, landingLang: 'en' },
        { domain: 'panel.strikeplagiarism.com',       instance: 'int', lang : 'en', pixel: false, ua: true,  conversion: true, fb: true, disableRegister:false, redirect: true, landingLang: 'en', oauth: ['sdu', 'satbayev'], saml: ['valencia', 'tartu', 'tallinn', 'tusofia', 'taltech', 'unibit', 'tampere'] }
        ]);
})();
