(function () {
    'use strict';

    function ClientConfigurationController(COUNTRYCODECONSTANT, CLIENTTYPECONSTANT) {
        var vm = this;

        vm.configuration = {
            reportSettings: [
                {label: 'ignoring-bibliography', model: 'ignoringBibliography'},
                {label: 'ignoring-citations', model: 'ignoringCitations'},
                {label: 'excluding-citations', model: 'excludingCitations'},
                {label: 'smartmarks-disabled', model: 'smartmarksDisabled'},
                {label: 'hide-docu-similarity-factor', model: 'hideDocuSimilarityFactor'},
                {label: 'hide-docu-similarity-factor-ext', model: 'hideDocuSimilarityFactorExt'},
                {label: 'show-reversed-similarity-factor', model: 'showReversedSimilarityFactor'},
                {label: 'report-2020-disabled', model: 'report2020Disabled'},
                {label: 'report-2020-operator-can-edit', model: 'report2020OperatorCanEdit'},
                {label: 'report-2020-no-submit-button', model: 'report2020NoSubmitButton'},
                {label: 'report-2020-view-only-in-email', model: 'report2020ViewOnlyInEmail'},
                {label: 'warning-thresholds', model: 'warningThresholds'},
                {label: 'report-2020-preview-source-plagiat', model: 'report2020PreviewSourcePlagiat'}
            ],
            addToDatabase: [
                {value: 'without'},
                {value: 'manually'},
                {value: 'automatically'},
                {value: 'delayed'}
            ],
            jsaOrppdSettings: [
                {label: 'jsa-integration', model: 'jsaIntegration'},
                {label: 'jsa-run-ai-detection', model: 'jsaRunAiDetection'},
                {label: 'orppd-integration', model: 'orppdIntegration'}
            ],
            addToDatabaseSettings: [
                {label: 'teacher-cant-delete-from-database', model: 'teacherCantDeleteFromDatabase'},
                {label: 'hide-add-without-checking', model: 'hideAddWithoutChecking'},
                {label: 'only-admin-can-add', model: 'onlyAdminCanAddToDatabase'},
            ],
            otherSettings: [
                {label: 'author-name-field-required', model: 'fieldRequiredAuthorName'},
                {label: 'send-alert-to-promoter', model: 'sendAlertToPromoter'},
                {label: 'send-alert-to-student', model: 'sendAlertToStudent'},
                {label: 'protocols-enabled', model: 'protocolsEnabled'},
                {label: 'document-import-enabled', model: 'documentImportEnabled'},
                {label: 'assignments-enabled', model: 'assignmentsEnabled'},
                {label: 'assignments-paid-corrections-enabled', model: 'assignmentsPaidCorrectionsEnabled'},
                {label: 'add-priority-from-contract-enabled', model: 'addPriorityFromContractEnabled'},
                {label: 'auto-translations-enabled', model: 'autoTranslationsEnabled'},
                {label: 'auto-translations-lang-selection', model: 'autoTranslationsLangSelection'},
                {label: 'tags-enabled', model: 'tagsEnabled'},
                {label: 'peer-review-enabled', model: 'peerReviewEnabled'},
            ],
            rareSettings: [
                {label: 'all-users-can-delete-documents', model: 'allUsersCanDeleteDocuments'},
                {label: 'all-users-can-delete-accounts', model: 'allUsersCanDeleteAccounts'},
                {label: 'hide-user-history', model: 'hideUserHistory'},
                {label: 'authors-simplified-fields', model: 'authorsSimplifiedFields'},
                {label: 'perm-user-do-not-use-copy-paste', model: 'permUserDoNotUseCopyPaste'},
                {label: 'perm-user-check-document-metric-similarity-before-add', model: 'permUserCheckDocumentMetricSimilarityBeforeAdd'},
                {label: 'perm-user-download-pdf-report', model: 'permUserDownloadPdfReport'},
                {label: 'perm-user-document-verification', model: 'permUserDocumentVerification'},
                {label: 'document-comparision', model: 'documentComparision'},
                {label: 'no-document-upload-for-students', model: 'noDocumentUploadForStudents'},
                {label: 'db-exchange-program-list-hidden', model: 'dbExchangeProgramListHidden'},
                {label: 'document-kind-due-date', model: 'documentKindDueDate'},
                {label: 'coordinator-not-required-by-default', model: 'coordinatorNotRequiredByDefault'},
                {label: 'old-phd-checking-year-field', model: 'oldPhdCheckingYearField'},
                {label: 'perm-user-upload-support', model: 'permUserUploadSupport'},
                {label: 'reserved-emails', model: 'reservedEmails'},//+ edition of the domain list
                {label: 'accept-regulations-enabled', model: 'acceptRegulationsEnabled'},//+ edition of the regulations url
                {label: 'password-change', model: 'passwordChange'}, //+ edition of the passwordChangePeriod
            ],
            aiDetectionSettings: [
                {label: 'ai-detection-second-score', model: 'aiDetectionSecondScore'},
                {label: 'ai-detection-hide-slider', model: 'aiDetectionHideSlider'},
                {label: 'ai-detection-only-two-pages', model: 'aiDetectionOnlyTwoPages'},
                {label: 'ai-detection-not-for-long-text', model: 'aiDetectionNotForLongText'},
                {label: 'ai-detection-show-only-probability', model: 'aiDetectionShowOnlyProbability'},
            ],
            grammarCheckSettings: [
                {label: 'grammar-check', model: 'enabled'},
            ],
            userSettings: [
                {label: 'allow-edit-document', model: 'allowEditDocument'},
                {label: 'perm-user-cant-see-the-documents-of-others', model: 'permUserCantSeeTheDocumentsOfOthers'},
                {label: 'perm-user-can-change-organisational-unit', model: 'permUserCanChangeOrganisationalUnit'},
                {label: 'user-teacher-can-change-promoter', model: 'userTeacherCanChangePromoter'},
                {label: 'perm-users-can-download-original-file', model: 'permUsersCanDownloadOriginalFile'}
            ],
            userGroupsSettings: [
                {label: 'allow-create-students', model: 'allowCreateStudents'},
            ],
            documentFieldSettings: [
                {label: 'index-number-present', model: 'indexNumberPresent'},
                {label: 'index-number-required', model: 'indexNumberRequired'},
            ],
            uploadPermissions: [
                {label: 'txt-support', model: 'txtSupport'},
                {label: 'pdf-support', model: 'pdfSupport'},
                {label: 'doc-support', model: 'docSupport'},
                {label: 'docx-support', model: 'docxSupport'},
                {label: 'odt-support', model: 'odtSupport'},
                {label: 'rtf-support', model: 'rtfSupport'},
                {label: 'ppt-support', model: 'pptSupport'},
                {label: 'pptx-support', model: 'pptxSupport'},
                {label: 'html-support', model: 'htmlSupport'}
            ]
        };

        vm.aiDetectionRoles = [
            'forStudent',
            'forOperator',
            'forLecturer',
            'forAdmin',
            'forApi'
        ]
        vm.aiDetectionOptions = [
            'alwaysDisabled',
            'defaultDisabled',
            'defaultEnabled',
            'alwaysEnabled'
        ];

        vm.available = function(model) {
            switch (model) {
                // case 'autoTranslationsEnabled':
                //     return vm.client.basicData.clientInstance === 'int';
                case 'oldPhdCheckingYearField':
                    return vm.client.basicData.clientInstance === 'ro';
                case 'permUserDocumentVerification':
                case 'documentFieldCard':
                    return !isClientTypePublishingHouse() && !isClientTypeSchool();
                case 'sendAlertToPromoter':
                    return !isClientTypePublishingHouse();
                case 'sendAlertToStudent':
                    return isClientTypeSchool();
                case 'assignmentsPaidCorrectionsEnabled':
                    return isClientTypeCollege() || isClientTypeCodeAccount();
                case 'userGroupsCard':
                    return isClientTypeCodeAccount() || isClientTypeStandardUniversity() || isClientTypeCollege();
                case 'userSettingsCard':
                case 'allowEditDocument':
                    return !isClientTypeSchool();
                case 'jsaOrppdCard':
                case 'jsaRunAiDetection':
                    return isOrppdAndJsaIntegrationAvailable()
                        || (isClientTypeAsap() && vm.client.basicData.countryId === COUNTRYCODECONSTANT.POLAND_COLUMBIA.id);
                case 'jsaIntegration':
                case 'orppdIntegration':
                    return isOrppdAndJsaIntegrationAvailable();
                case 'authorsSimplifiedFields':
                case 'userTeacherCanChangePromoter':
                    return isClientTypeStandardUniversity() || isClientTypeCodeAccount();
                case 'permUserCantSeeTheDocumentsOfOthers':
                    return isClientTypeStandardUniversity() || isClientTypeCodeAccount() || isClientTypePublishingHouse();
                default: return true;
            }
        };

        function isOrppdAndJsaIntegrationAvailable() {
            return vm.client.basicData.countryId === COUNTRYCODECONSTANT.POLAND_COLUMBIA.id && isClientTypeStandardUniversity();
        }

        function isClientTypeStandardUniversity() {
            return vm.client.basicData.clientType.id === CLIENTTYPECONSTANT.CLIE_TYPE_NORMAL.id;
        }

        function isClientTypeAsap() {
            return vm.client.basicData.clientType.id === CLIENTTYPECONSTANT.CLIE_TYPE_NORMAL.id;
        }

        function isClientTypePublishingHouse() {
            return vm.client.basicData.clientType.id === CLIENTTYPECONSTANT.CLIE_TYPE_PUBLISHING_HOUSE.id;
        }

        function isClientTypeCodeAccount() {
            return vm.client.basicData.clientType.id === CLIENTTYPECONSTANT.CODE_ACCOUNT.id;
        }

        function isClientTypeSchool() {
            return vm.client.basicData.clientType.id === CLIENTTYPECONSTANT.SCHOOL.id;
        }

        function isClientTypeCollege() {
            return vm.client.basicData.clientType.id === CLIENTTYPECONSTANT.COLLEGE.id;
        }

    }

    angular
        .module('plagiat2017App')
        .component('clientConfiguration', {
            templateUrl: 'app/client/client-add/steps/client-configuration.html',
            controller: ClientConfigurationController,
            controllerAs: 'vm',
            bindings: {
                client: '=',
                isSaving: '=',
                isEdit: '=',
                previousStep: '&',
                saveOrUpdate: '&',
            }
        });
})();
