(function () {
    'use strict';

    angular
        .module('plagiat2017App')
        .controller('ComparisionController', ComparisionController);

    ComparisionController.$inject = ['ComparisionService', 'ModalService', '$mdDialog'];

    function ComparisionController(ComparisionService, ModalService, $mdDialog) {
        var vm = this;
        vm.obj = {};
        vm.pending = false;
        vm.filesBase = [];
        vm.filesCompared = [];
        vm.filesBaseError = false;
        vm.filesComparedError = false;
        var formData = new FormData();
        vm.getBaseFiles = getBaseFiles;
        vm.getComparedFiles = getComparedFiles;

        vm.downloadComparisionReport = function () {
            vm.pending = true;
            ModalService.showPleaseWaitDialog(false);
            ComparisionService.downloadComparisionReport(vm.obj, 'report').then(
                function () {
                    vm.pending = false;
                }, function () {
                    $mdDialog.hide();
                    vm.pending = false;
                });
        }

        function getBaseFiles($files, $invalidFiles) {
            formData = new FormData();
            vm.filesBaseError = false;
            if ($files.length === 0) {
                vm.filesBase = null;
                return;
            }
            if ($invalidFiles.length === 0) {
                vm.filesBase = $files;
                angular.forEach(vm.filesBase, function (value, key) {
                    vm.fileName = value.name;
                    formData.append(key, value);
                });
                ComparisionService.convertFile(formData).then(function (response) {
                    vm.obj.title1 = vm.filesBase[0].name;
                    vm.obj.content1 = response.data;
                }, function () {
                    vm.filesBaseError = true;
                });
            } else {
                vm.filesBaseError = true;
            }
        }

        function getComparedFiles($files, $invalidFiles) {
            formData = new FormData();
            vm.filesComparedError = false;
            if ($files.length === 0) {
                vm.filesCompared = null;
                return;
            }
            if ($invalidFiles.length === 0) {
                vm.filesCompared = $files;
                angular.forEach(vm.filesCompared, function (value, key) {
                    vm.fileName = value.name;
                    formData.append(key, value);
                });
                ComparisionService.convertFile(formData).then(function (response) {
                    vm.obj.title2 = vm.filesCompared[0].name;
                    vm.obj.content2 = response.data;
                }, function () {
                    vm.filesComparedError = true;
                });
            } else {
                vm.filesComparedError = true;
            }
        }

    }
})();
