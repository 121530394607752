(function () {
  'use strict';

  angular
    .module('plagiat2017App')
    .factory('ExtraOptionsService', ExtraOptionsService);

  ExtraOptionsService.$inject = [];

  function ExtraOptionsService() {

    var service = {
      recalculatePrices: recalculatePrices,
      getCurrentDocumentOptions: getCurrentDocumentOptions,
      isAiDetectionEnabled: isAiDetectionEnabled,
      isOptionDisabled: isOptionDisabled,
      resetForm: resetForm,
    };
    return service;

    function recalculatePrices(documentSize, vm) {
      vm.size = documentSize;
      vm.basePrice = (documentSize <= 500 && vm.freeChecksAvailable) ? 0
        : Math.ceil(documentSize / vm.charactersPerToken);
      vm.totalPrice = vm.basePrice;
      angular.forEach(vm.documentExtraOptions, function (option) {
        option.price =  Math.ceil(documentSize / option.charactersPerToken);
        if (option.id === 32 && documentSize < 1000) {
          option.chosen = false;
        }
        if (option.chosen) {
          vm.totalPrice += option.price;
        }
        if (option.chosen && option.id == 32 && vm.translation) {
          vm.translation.chosen = false;
        }
      });
    }

    function resetForm(vm) {
      vm.basePrice = null
      vm.totalPrice = null;
      vm.documentExtraOptions = null;
      vm.translation = {};
    }

    function getCurrentDocumentOptions(vm) {
      return vm.documentExtraOptions.reduce(function (sum, option) {
        if (option.chosen) {
          return sum + option.id;
        } else {
          return sum;
        }
      }, 0);
    }

    function isAiDetectionEnabled(vm) {
      vm.aiEnabled = false;
      angular.forEach(vm.documentExtraOptions, function (option) {
        if (option.chosen && option.id == 32) {
          vm.aiEnabled = true;
        }
      });
      return vm.aiEnabled;
    }

    function isOptionEnabled(vm, id) {
      var result = false;
      angular.forEach(vm.documentExtraOptions, function (option) {
        if (option.chosen && option.id === id) {
          result = true;
        }
      });
      return result;
    }

    function isOptionDisabled(vm, documentSize, option) {
      if (option.id === 32) { // 32  => ai id
        // do not allow AI detection for very small and very big documents
        return documentSize < 1000 || documentSize >= 300000;
      }
      if (option.id === 4) { // 4  => ukrint id
        var priorityEnabled = isOptionEnabled(vm, 1); // 1 => priority id
        if (priorityEnabled === true) {
          option.chosen = false;
          recalculatePrices(documentSize, vm);
        }
        return priorityEnabled;
      }
      return false;
    }

  }
})();
