(function () {
    'use strict';

    angular
        .module('plagiat2017App')

        /*
         Languages codes are ISO_639-1 codes, see http://en.wikipedia.org/wiki/List_of_ISO_639-1_codes
         They are written in English to avoid character encoding issues (not a perfect solution)
         */
        .constant('LANGUAGES', [
            'en',
            'pl',
            'es',
            'az',
            'de',
            'fr',
            'ka',
            'kk',
            'ro',
            'ru',
            'tr',
            'uk',
            'bg',
            'uz'
            // jhipster-needle-i18n-language-constant - JHipster will add/remove languages in this array
        ])
        .constant('AUTO_TRANSLATIONS_LANGS', [
            'ar_EG',
            'hy_AM',
            'az_AZ',
            'eu_ES',
            'be_BY',
            'bs_BA',
            'bg_BG',
            'ca_ES',
            'zh-Hans_CN',
            'hr_HR',
            'cs_CZ',
            'da_DK',
            'en_GB',
            'et_EE',
            'fi_FI',
            'fr_FR',
            'fy_NL',
            'ka_GE',
            'de_DE',
            'el_GR',
            'he_IL',
            'hi_IN',
            'hu_HU',
            'is_IS',
            'id_ID',
            'ga_IE',
            'it_IT',
            'ja_JP',
            'kk_KZ',
            'ko_KR',
            'ky_KG',
            'la_VAT',
            'lv_LV',
            'lt_LT',
            'ms_MY',
            'mt_MT',
            'mn_MN',
            'no_NO',
            'fa_IR',
            'pl_PL',
            'pt_PT',
            'pa_PK',
            'ro_RO',
            'ru_RU',
            'sk_SK',
            'es_ES',
            'sv_SE',
            'tg_TJ',
            'ta_IN',
            'tr_TR',
            'uk_UA',
            'ur_PK',
            'uz_UZ',
            'vi_VN'
        ])
    ;
})();
