(function () {
    'use strict';

    angular
        .module('plagiat2017App')
        .factory('ComparisionService', ComparisionService);

    ComparisionService.$inject = ['$http', 'FileSaver', 'PlagiatValidationService', '$mdDialog', '$translate', 'DateUtils', '$q'];

    function ComparisionService($http, FileSaver, PlagiatValidationService, $mdDialog, $translate, DateUtils, $q) {

        var service = {
            downloadComparisionReport: downloadComparisionReport,
            convertFile: convertFile
        };
        var requestUrl = 'api/comparision';
        return service;

        function downloadComparisionReport(obj, fileName) {
            var url = requestUrl + '/pdf';

            return $http({
                url : url,
                method: "POST",
                data: obj,
                headers: {
                    'Content-type': 'application/json'
                },
                responseType: 'arraybuffer'
            }).then(
                function(success) {
                    onSuccess(success, fileName, '.pdf');
                },
                function(error) {
                    PlagiatValidationService.getModalError(error);
                }
            );
        }

        function onSuccess(success, fileName, reportExtension) {

            console.log('hide');
            $mdDialog.hide();
            var blob = new Blob(
                [ success.data ],
                {
                    type: "text/pdf; encoding=UTF-8"
                }
            );
            saveAs(blob, fileName + reportExtension);
        }

        function convertFile(obj) {
            var request = {
                method: 'POST',
                url: requestUrl + '/convert-file-to-text',
                data: obj,
                headers: {
                    'Content-Type': undefined,
                    'Charset': 'UTF-8'
                },
                responseType: 'text'
            };
            return $http(request).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }
    }
})();
