(function () {
    'use strict';

    angular.module('plagiat2017App')
      .factory('CountryModel', function () {
          function CountryModel() {
              return [
                  "PL",
                  "AF",
                  "AL",
                  "DZ",
                  "AD",
                  "AO",
                  "AI",
                  "AQ",
                  "AG",
                  "SA",
                  "AR",
                  "AM",
                  "AW",
                  "AU",
                  "AT",
                  "AZ",
                  "BS",
                  "BH",
                  "BD",
                  "BB",
                  "BE",
                  "BZ",
                  "BJ",
                  "BM",
                  "BT",
                  "BY",
                  "BO",
                  "BA",
                  "BW",
                  "BR",
                  "BN",
                  "IO",
                  "VG",
                  "BG",
                  "BF",
                  "BI",
                  "CL",
                  "CN",
                  "HR",
                  "CW",
                  "CY",
                  "TD",
                  "ME",
                  "CZ",
                  "UM",
                  "DK",
                  "CD",
                  "DM",
                  "DO",
                  "DJ",
                  "EG",
                  "EC",
                  "EE",
                  "ET",
                  "FK",
                  "FJ",
                  "PH",
                  "FI",
                  "FR",
                  "TF",
                  "GA",
                  "GM",
                  "GH",
                  "GI",
                  "GR",
                  "GD",
                  "GL",
                  "GE",
                  "GU",
                  "GY",
                  "GF",
                  "GP",
                  "GT",
                  "GN",
                  "GW",
                  "GQ",
                  "HT",
                  "ES",
                  "IC",
                  "NL",
                  "HN",
                  "HK",
                  "IN",
                  "ID",
                  "IQ",
                  "IR",
                  "IE",
                  "IS",
                  "IL",
                  "JM",
                  "JP",
                  "YE",
                  "JO",
                  "KY",
                  "KH",
                  "CM",
                  "CA",
                  "QA",
                  "KZ",
                  "KE",
                  "KG",
                  "KI",
                  "CO",
                  "KM",
                  "CG",
                  "KP",
                  "KR",
                  "XK",
                  "CR",
                  "CU",
                  "KW",
                  "LA",
                  "LS",
                  "LB",
                  "LR",
                  "LY",
                  "LI",
                  "LT",
                  "LU",
                  "LV",
                  "MK",
                  "MG",
                  "YT",
                  "MO",
                  "MW",
                  "MV",
                  "MY",
                  "ML",
                  "MT",
                  "MP",
                  "MA",
                  "MQ",
                  "MR",
                  "MU",
                  "MX",
                  "FM",
                  "MM",
                  "MD",
                  "MC",
                  "MN",
                  "MS",
                  "MZ",
                  "NA",
                  "NR",
                  "NP",
                  "BQ",
                  "DE",
                  "NE",
                  "NG",
                  "NI",
                  "NU",
                  "NF",
                  "NO",
                  "NC",
                  "NZ",
                  "OM",
                  "PK",
                  "PW",
                  "PA",
                  "PG",
                  "PY",
                  "PE",
                  "PN",
                  "PF",
                  "PR",
                  "PT",
                  "ZA",
                  "CF",
                  "CV",
                  "RE",
                  "RU",
                  "RO",
                  "RW",
                  "EH",
                  "KN",
                  "LC",
                  "VC",
                  "BL",
                  "MF",
                  "PM",
                  "SV",
                  "WS",
                  "AS",
                  "SM",
                  "SN",
                  "RS",
                  "SC",
                  "SL",
                  "SG",
                  "SX",
                  "SK",
                  "SI",
                  "SO",
                  "LK",
                  "US",
                  "SZ",
                  "SD",
                  "SS",
                  "SR",
                  "SJ",
                  "SY",
                  "CH",
                  "SE",
                  "TJ",
                  "TH",
                  "TW",
                  "TZ",
                  "TG",
                  "TK",
                  "TO",
                  "TT",
                  "TN",
                  "TR",
                  "TM",
                  "TC",
                  "TV",
                  "UG",
                  "UA",
                  "UY",
                  "UZ",
                  "VU",
                  "WF",
                  "VA",
                  "HU",
                  "VE",
                  "GB",
                  "XI",
                  "VN",
                  "IT",
                  "CI",
                  "BV",
                  "CX",
                  "IM",
                  "SH",
                  "CK",
                  "VI",
                  "HM",
                  "CC",
                  "MH",
                  "FO",
                  "SB",
                  "ST",
                  "ZM",
                  "ZW",
                  "AE"
              ];
          }

          return CountryModel;
      });
})();
