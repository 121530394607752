(function () {
    'use strict';

    angular.module('PaymentFormRequestModel', [])
  .factory('PaymentFormRequest', function () {

      function PaymentForm() {
          this.phase = 'stepTokens';
          this.step1 = true;
          this.step2 = false;
          this.step3 = false;
          this.tokens = 0;
          this.invoiceRequired = '';
          this.isCompany = true;
          this.agreement = false;
          this.invoice = {
              company: '',
              nip: '',
              street: '',
              city: '',
              zip: '',
              phone: '',
              country: ''
          };

          this.paymentMethod = '';
          this.creditCard = {
              name: '',
              cardNo: '',
              dateFrom: '',
              dateTo: '',
              ccv: '',
              country: ''
          };
          this.sms = {
              value: ''
          };
      }

      return PaymentForm;
  });
})();
