(function() {
    'use strict';

    angular
        .module('plagiat2017App')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('payments', {
            parent: 'app',
            url: '/payments?key&documentId',
            data: {
                authorities: ['PERM_USER_ALL_PAYMENTS', 'PERM_USER_PAYMENT'],
                pageTitle: 'global.menu.payments'
            },
            views: {
                'content@': {
                    templateUrl: 'app/account/payments/payments.html',
                    controller: 'PaymentsController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '0',
                    squash: true
                },
                sort: {
                    value: 'paymentDate,desc',
                    squash: true
                }
            },
            onEnter: ['$state', '$stateParams', 'AccountService', function ($state, $stateParams,  AccountService) {
                if (AccountService.clientType.isCodeAccount()) {
                    $state.go('code-payments', $stateParams);
                }
            }],
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort)
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('payments');
                    $translatePartialLoader.addPart('country');
                    $translatePartialLoader.addPart('users');
                    return $translate.refresh();
                }]
            }
        });
    }

})();
