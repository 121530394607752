(function () {
  'use strict';

	function PaymentsInvoicesDetailsController(DetectInstanceService) {
		var vm = this;
    vm.detectedInstance = DetectInstanceService.instance();
    vm.isRomania = vm.detectedInstance === 'ro';
	}

  angular
    .module('plagiat2017App')
    .component('paymentsInvoicesDetails', {
      templateUrl: 'app/account/payments/payments-invoices-details/payments-invoices-details.template.html',
			controller : PaymentsInvoicesDetailsController,
      bindings: {
        onInvoice: '<',
        onInvoiceDetails: '<'
      }
    });
})();
